/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useStaticQuery, graphql } from 'gatsby'

import Helmet from '../../components/HelmetHelper'
import Container from '../../components/Container'
import Text, { TEXT_SIZE_SMALL } from '../../components/content/Text'
import { THEME_TAUPE, THEME_CITRON, colors, media } from '../../constants'
import Slide, {
	SLIDE_LAYOUT_MOCK_FULL_SCREEN,
	SLIDE_LAYOUT_FULL_BLEED_IMAGE,
} from '../../components/Slide'
import {
	LayoutSplitHeading,
	LayoutIndented,
	LayoutCol2,
	LayoutVerticalCadence,
	LAYOUT_V_CADENCE_SIZE_MEDIUM,
} from '../../components/content/Layout'
import WorkShowcaseMobile, {
	WORK_SHOWCASE_LAYOUT_MOBILE_4UP,
} from '../../components/content/WorkShowcaseMobile'
import FullBleedImage from '../../components/content/FullBleedImage'
import { InlineLink, StandaloneLink } from '../../components/content/Link'
import { provideAccentTheme } from '../../context/AccentThemeContext'
import Highlight from '../../components/content/Highlight'
import Showcase, {
	SHOWCASE_LAYOUT_2X2,
} from '../../components/content/Showcase'

import { caseStudyLinkData } from '../../workData'

const nextPage = caseStudyLinkData.google.next

const GooglePage = ({ accentTheme }) => {
	const data = useStaticQuery(graphql`
		fragment GStoreScreen on File {
			childImageSharp {
				fixed(width: 1170, height: 622, quality: 100, cropFocus: NORTH) {
					...GatsbyImageSharpFixed_noBase64
				}
			}
		}

		fragment GStoreMobileScreen on File {
			childImageSharp {
				fixed(width: 720, height: 1280, quality: 100, cropFocus: NORTH) {
					...GatsbyImageSharpFixed_noBase64
				}
			}
		}

		query {
			image1: file(relativePath: { eq: "google/google-devices@2x.jpg" }) {
				...ImageFluidFull
			}
			store1: file(relativePath: { eq: "google/gstore-01@2x.jpg" }) {
				...ImageFluidFull
			}
			store2: file(relativePath: { eq: "google/gstore-02@2x.jpg" }) {
				...ImageFluidFull
			}
			store3: file(relativePath: { eq: "google/gstore-03@2x.jpg" }) {
				...ImageFluidFull
			}
			store4: file(relativePath: { eq: "google/gstore-04@2x.jpg" }) {
				...ImageFluidFull
			}

			storeMobileSubscriptions: file(
				relativePath: { eq: "google/raw/gstore-mobile-subscriptions.png" }
			) {
				...GStoreMobileScreen
			}
			storeMobileSubscribe: file(
				relativePath: { eq: "google/raw/gstore-mobile-subscribe.png" }
			) {
				...GStoreMobileScreen
			}
			storeMobilePay: file(
				relativePath: { eq: "google/raw/gstore-mobile-pay-method.png" }
			) {
				...GStoreMobileScreen
			}
			storeMobileCart: file(
				relativePath: { eq: "google/raw/gstore-mobile-cart.png" }
			) {
				...GStoreMobileScreen
			}
		}
	`)

	return (
		<div>
			<Helmet>
				<title>Google</title>
				<meta content="Google Case Study" property="og:title" />
				<meta content="Google Case Study" name="twitter:title" />
				<meta
					property="og:description"
					content="Free Association is a preferred agency partner of Google, and our product teams have been involved in a wide range of work since 2012."
				/>
				<meta
					name="description"
					content="Free Association is a preferred agency partner of Google, and our product teams have been involved in a wide range of work since 2012."
				/>
				<meta
					content="https://freeassociation.com/share/google.jpg"
					property="og:image"
				/>
				<meta
					content="https://freeassociation.com/share/google.jpg"
					property="twitter:image:src"
				/>
			</Helmet>
			<Slide layout={SLIDE_LAYOUT_MOCK_FULL_SCREEN}>
				<Container>
					<LayoutIndented>
						<Text>
							<h1 className="--eyebrow">Google</h1>
							<p className="--headline">Ten years of product evolution.</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutSplitHeading title="Overview">
							<Text defaultParagraph={TEXT_SIZE_SMALL}>
								<p>
									Free Association is a preferred agency partner of Google, and
									our product teams have been involved in a wide range of work
									since 2012. We have collaborated closely, often embedded, to
									create products, platforms, and systems that serve consumers
									and teams within Google. From Google Store e-commerce UX, to
									experience vision for new ventures, to the brand system
									steering Google Hardware creative, Free Association has
									blended seamlessly into many Google teams to drive informed
									design decisions and results.
								</p>
							</Text>
						</LayoutSplitHeading>
						<LayoutSplitHeading title="Services">
							<Text defaultParagraph={TEXT_SIZE_SMALL}>
								<ul className="--unstyled-mediumUp --col-2">
									<li>Product Leadership</li>
									<li>Strategic Planning</li>
									<li>Project Management</li>
									<li>UX &amp; Market Research</li>
									<li>Testing &amp; Measurement</li>
									<li>Information Architecture</li>
									<li>User Experience Design</li>
									<li>UI &amp; Visual Design</li>
									<li>Interaction Prototyping</li>
									<li>Brand Systems &amp; Guidelines</li>
								</ul>
							</Text>
						</LayoutSplitHeading>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}>
				<div
					css={{
						backgroundColor: colors.citron, // Match the background of the graphic
					}}
				>
					<FullBleedImage fluidImageData={data.image1.childImageSharp.fluid} />
				</div>
			</Slide>
			<Slide>
				<Container>
					<LayoutIndented>
						<Text>
							<p>
								We serve as the UX growth team for Google Store, working with a
								cross-functional team to validate and launch multiple
								experiments per month. Our focus is improving usability, driving
								conversion, and increasing topline revenue.
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
					},
				}}
			>
				<Container maxWidth="75rem">
					<Showcase
						images={[
							data.store1.childImageSharp.fluid,
							data.store2.childImageSharp.fluid,
							data.store3.childImageSharp.fluid,
							data.store4.childImageSharp.fluid,
						]}
						layout={SHOWCASE_LAYOUT_2X2}
					/>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutIndented>
						<Text>
							<p>
								We’ve collaborated on many key initiatives for Google Store,
								including navigation, subscriptions, accounts, Stadia gaming,
								Pixel carrier activation, and Google One membership.
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
					},
				}}
			>
				<Container maxWidth="75rem">
					<WorkShowcaseMobile
						layout={WORK_SHOWCASE_LAYOUT_MOBILE_4UP}
						items={[
							{ src: data.storeMobileSubscriptions.childImageSharp.fixed.src },
							{ src: data.storeMobileSubscribe.childImageSharp.fixed.src },
							{ src: data.storeMobilePay.childImageSharp.fixed.src },
							{ src: data.storeMobileCart.childImageSharp.fixed.src },
						]}
						showChrome={true}
					/>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutIndented>
							<Text>
								<p>
									We’ve partnered with many other talented teams at Google over
									the years, spanning these business verticals.
								</p>
							</Text>
						</LayoutIndented>
						<LayoutCol2 verticalCadenceSize={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
							<Highlight title="Retail">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p class="--grey">
										Design and prototyping to imagine next generation retail
										experiences in-store.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Creative Lab">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p class="--grey">
										Envisioning customer experiences for small businesses
										seeking integrated Google solutions.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Access &amp; Energy">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p class="--grey">
										Mapping marketing strategy and UX to customer needs for the
										Wifi hardware product launch.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Hardware Creative">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p class="--grey">
										Inspiring and educating customers with elevated brand
										systems for Google Hardware.
									</p>
								</Text>
							</Highlight>
						</LayoutCol2>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide prefferedTheme={accentTheme}>
				<Container>
					<LayoutIndented>
						<Text>
							<blockquote>
								<p>
									“I’ve worked closely with Free Association for more than four
									years at Google. During that time, we went through three major
									reorganizations. At every point their team adapted,
									accommodated, achieved, and astounded. This agility alone is
									why I will always welcome their services.”
								</p>
								<footer>
									<p className="--small">
										<strong>Shawn Dumas</strong>
										<br />
										UX Engineer, Manager
									</p>
								</footer>
							</blockquote>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutIndented>
						<Text>
							<p className="--eyebrow">Next</p>
							<p className="--headline">
								<StandaloneLink to={nextPage.to}>
									{nextPage.title}
								</StandaloneLink>
							</p>
							<p className="--small">
								<InlineLink to="/work">All Work</InlineLink>
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
		</div>
	)
}

export default provideAccentTheme(GooglePage, THEME_CITRON)
